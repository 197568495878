import { CatalogoSearchParams } from '../../type/filter'
import { GetProductsList } from './types'
import { apiCall } from '../../../../common/api/api'

export function getCatalogData<T>(
  catalogKey: string,
  data: CatalogoSearchParams
): Promise<GetProductsList<T>> {
  const url = `${process.env.GATSBY_API_URL}/${catalogKey}/search`
  const queryParams = new URLSearchParams({
    filters: JSON.stringify(data.filters),
    pagination: JSON.stringify(data.pagination),
    order: JSON.stringify(data.order),
  })

  return apiCall(`${url}?${queryParams.toString()}`, {
    method: 'GET',
    auth: true,
  })
}
