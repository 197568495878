import {
  CestaDiscount,
  CestaState,
  Product,
  Service,
} from '../context/reducers/cestaReducer'
import { apiCall, apiCallRetry } from './api'
import { AvailabilityDate } from '../types/Taller'
import { ITime } from './citaPrevia'
import { PaymentObject } from '../../shared/cesta/type/Cesta'
import { UserState } from '../context/reducers/userReducer'

const baseUrl = `${process.env.GATSBY_API_URL}`

interface ProductInfo {
  products: Product[]
  services: Service[]
}
export const productInfo = (
  id_navision: string,
  cupones: string[],
  userInfo: UserState
): Promise<ProductInfo> => {
  return apiCall(
    `${baseUrl}/cesta-product/${encodeURIComponent(id_navision)}`,
    {
      method: 'POST',
      body: JSON.stringify({ cupones, userInfo }),
    }
  )
}

export const productInfoFromId = (
  id_site_producto: string,
  userInfo: UserState
): Promise<Product> => {
  return apiCall(
    `${baseUrl}/productos/${encodeURIComponent(id_site_producto)}`,
    {
      method: 'POST',
      body: JSON.stringify({ userInfo }),
    }
  )
}

interface CestaLoadData extends CestaUpdateData {
  cestaID: string
  matricula: string | null
}
export const load = (id_cesta: string): Promise<CestaLoadData> => {
  return apiCall(`${baseUrl}/cesta/${encodeURIComponent(id_cesta)}`)
}

interface CestaUpdateData {
  products: Product[]
  services: Service[]
  totals: {
    totalProductos: number
    total: number
  }
  generaFactura: boolean
}
export const update = (
  cestaState: CestaState,
  userInfo?: UserState
): Promise<CestaUpdateData> => {
  return apiCall(`${baseUrl}/cesta/update`, {
    method: 'POST',
    body: JSON.stringify({ cestaState, userInfo }),
  })
}

export function selectTimeCesta(
  codTienda: string,
  date: string,
  time: string,
  email: string
): Promise<string> {
  return apiCall(`${baseUrl}/reserve-time`, {
    method: 'POST',
    body: JSON.stringify({ codTienda, date, time, email }),
  })
}

interface CreatedOrders {
  products: Product[]
  services: Service[]
  discount: CestaDiscount[]
  totalPayment: PaymentObject
  partialPayment: PaymentObject
  tallerPayment: PaymentObject
  generaFactura: boolean
}
export const createOrder = (
  cestaState: CestaState,
  userInfo: UserState
): Promise<CreatedOrders> => {
  return apiCall(`${baseUrl}/pedidos`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ cestaState, userInfo }),
  })
}

export function paymentResult(data, selectedDate) {
  return apiCall(`${baseUrl}/pedidos`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify({ data, selectedDate }),
  })
}

export interface RetrieveDatesResult {
  newSelectedProducts: Product[]
  nextDays: AvailabilityDate[]
  neumaticosAdjusted: Product[]
}
export function retrieveDates(
  selectedProducts: Product[],
  codTienda: string,
  numDays = 25,
  userInfo: UserState
): Promise<RetrieveDatesResult> {
  return apiCallRetry(`${process.env.GATSBY_API_URL}/consult-dates`, {
    retries: 3,
    retryDelay: 10000,
    method: 'POST',
    body: JSON.stringify({
      selectedProducts,
      codTienda,
      numDays,
      userInfo,
    }),
  })
}

export type RetrieveTimesResult = ITime[]

export function retrieveTimes(
  codTienda: string,
  date: string,
  products: Product[],
  email: string
): Promise<RetrieveTimesResult> {
  return apiCall(`${process.env.GATSBY_API_URL}/consult-times`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ codTienda, date, products, email }),
  })
}

export function deleteCesta(cestaID: string) {
  return apiCall(`${process.env.GATSBY_API_URL}/delete-cesta/${cestaID}`, {
    method: 'DELETE',
  })
}

export function unavailableNeumaticos({ unavailableProducts, userInfo }) {
  return apiCall(`${process.env.GATSBY_API_URL}/unavailable-products`, {
    method: 'POST',
    body: JSON.stringify({ unavailableProducts, userInfo }),
  })
}
